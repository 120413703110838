import { Controller } from "stimulus"

export default class extends Controller {
  initialize() {
    this.typingTimeout = null;
  }

  submit() {
    let controller = this;
    let form = controller.element;

    controller.updateURL(form)
    controller.element.requestSubmit();
  }

  submitAfterDelay() {
    let controller = this;
    let delayInMilliseconds = 1000;

    clearTimeout(controller.typingTimeout);
    controller.typingTimeout = setTimeout(() => controller.submit(), delayInMilliseconds);
  }

  updateURL(form) {
    let formData = new FormData(form);
    let search = new URLSearchParams(formData);
    let queryString = search.toString();
    let newURL = window.location.origin + window.location.pathname + '?' + queryString

    history.replaceState('', '', newURL)
  }
}
