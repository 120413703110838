import { Controller } from "stimulus";
import $ from 'jquery';

import arrowDownImage from '../images/icons/arrow_down.svg';

export default class extends Controller {
  connect() {
    // Have select2 dispatch regular change events in order for it to work with
    // our stimulus controllers.
    // source: https://discuss.hotwire.dev/t/common-patterns/124/8
    this.$element = $(this.element);
    this.$element
              .select2({
                width: 'resolve'
              })
              .on('select2:select select2:unselect', () => {
                this.element.dispatchEvent(
                  new Event('change', { bubbles: true })
                );
              })
              .on('select2:open select2:close', () => {
                this.fixSearchFieldPosition();
              });


    setTimeout(() => this.customizeView(), 1);
  }

  disconnect() {
    $(this.element).select2('destroy');
  }

  get select2() {
    return this.$element.siblings('.select2');
  }

  customizeView() {
    // do not insert arrow if it already exists
    if(this.select2.find('.select2-arrow').length) return;

    let dropdownArrowIcon = $('<img>').attr('src', arrowDownImage).addClass('select2-arrow');

    this.select2.find('.select2-selection').append(dropdownArrowIcon);
  }

  // On Chrome the position of the search field will be incorrect after opening/ closing the dropdown.
  // It ignores the parents padding until the element is refreshed.
  // We force it here to refresh by changing its width.
  fixSearchFieldPosition() {
    const searchField = this.select2.find('.select2-search__field');
    const previousValue = searchField.css('width');

    searchField.css('width', 0);

    setTimeout(() => {
      searchField.css('width', previousValue);
    }, 1);
  }
}
