// small utility that lets us resolve/ reject a promise from outside
function DeferredPromise() {
    this.promise = new Promise((resolve, reject) => {
        this.reject = reject;
        this.resolve = resolve;
    });
}

function MapsApiLoader() {
    this.isLoaded = false;

    this.deferredMapsApiPromise = new DeferredPromise();
    this.deferredClustererApiPromise = new DeferredPromise();

    const apiScript = document.createElement('script');

    const clusterScript = document.createElement('script');
    clusterScript.src = "https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js";

    // As google maps loads a couple of other scripts
    //   and offers us the ability to let it call a callback after it is finished (mind the extra parameter in the script src),
    //   we bind this maps api promise resolve to a global callback
    window.onGoogleMapsLoad = () => {
        this.deferredMapsApiPromise.resolve();
    }
    apiScript.onerror = () => {
        this.deferredMapsApiPromise.reject.apply(this, arguments);
    }

    clusterScript.onload = () => {
        this.deferredClustererApiPromise.resolve();
    }
    clusterScript.onerror = () => {
        this.deferredClustererApiPromise.reject.apply(this, arguments);
    }

    Promise.all([
        this.deferredMapsApiPromise.promise,
        this.deferredClustererApiPromise.promise
    ]).then(() => this.isLoaded = true);

    this.load = function() {
        if(this.isLoaded) return;

        this.apiKey = document.querySelectorAll('meta[property="google_maps_api_key"]')[0].content;
        apiScript.src = "https://maps.googleapis.com/maps/api/js?key=" + this.apiKey + "&callback=onGoogleMapsLoad";

        const head = document.getElementsByTagName("head")[0];

        head.appendChild(apiScript);
        head.appendChild(clusterScript);
    };

    this.onLoad = function () {
        return Promise.all([
            this.deferredMapsApiPromise.promise,
            this.deferredClustererApiPromise.promise
        ]);
    };
}

export default new MapsApiLoader(); // export singleton;
