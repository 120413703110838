import { Controller } from "stimulus"

export default class extends Controller {

  // This setup is taken from MDN
  // https://developer.mozilla.org/en-US/docs/Web/Guide/Printing#print_an_external_page_without_opening_it
  connect() {
    this.hiddenFrame = document.createElement("iframe");

    this.hiddenFrame.addEventListener(
      "load",
      () => {
        this.hiddenFrame.contentWindow.focus();
        this.hiddenFrame.contentWindow.print();
      }
    );

    this.hiddenFrame.style.position = "fixed";
    this.hiddenFrame.style.right = "0";
    this.hiddenFrame.style.bottom = "0";
    this.hiddenFrame.style.width = "0";
    this.hiddenFrame.style.height = "0";
    this.hiddenFrame.style.border = "0";

    this.appendedFrame = null;
  }

  disconnect() {
    document.body.removeChild(this.appendedFrame);
  }

  print() {
    let printURL = window.location.origin + window.location.pathname + '/print' + window.location.search
    this.hiddenFrame.src = printURL;
    this.appendedFrame = document.body.appendChild(this.hiddenFrame);

    this.appendedFrame.contentWindow.addEventListener(
      "beforeunload",
      () => document.body.removeChild(this.appendedFrame)
    );

    this.appendedFrame.contentWindow.addEventListener(
      "afterprint",
      () => document.body.removeChild(this.appendedFrame)
    );
  }
}
