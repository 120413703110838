import { Controller } from "stimulus"
import CardNumberFormatter from "../../js/util/card_number_formatter";
import StringUtils from "../../js/util/string_utils";

export default class extends Controller {
  connect() {
    this.$element = $(this.element);

    this.onInputHandler = () => {
      this.number = this.element.value;
    };
    this.$element.on('input', this.onInputHandler);
  }

  disconnect() {
    this.$element.off('input', this.onInputHandler);
  }

  set number(value) {
    if(this.element.value === CardNumberFormatter.format(value)) return;

    const cursor = this.captureCursor();

    this.element.value = CardNumberFormatter.format(value);

    this.restoreCursor(cursor);

    // If user pastes the number,
    //   the event won't be fired at the right time
    //   for the balance_controller to recognize a valid card_number and enable the button.
    // Workaround for this is just to fire the event again if we changed the format.
    this.element.dispatchEvent(new Event('input'));
  }

  get number() {
    return this.element.value;
  }

  captureCursor() {
    const input = this.element;
    const position = input.selectionEnd;
    const numberOfSpaces = StringUtils.count(input.value.substr(0, position), ' ');

    return {
      positionInString: position,
      positionInNumber: position - numberOfSpaces,
    }
  }

  restoreCursor(capturedCursor) {
    const input = this.element;
    const numberOfSpaces = StringUtils.count(input.value.substr(0, capturedCursor.positionInString), ' ');

    input.selectionEnd = capturedCursor.positionInNumber + numberOfSpaces;
  }
}
