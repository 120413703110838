import { Controller } from "stimulus"

import MapConsent from '../../js/util/map_consent';
import MapsApiLoader from '../../js/util/maps_api_loader'

const imageSizes = [52, 56, 66, 78, 90];
// the text offset is relative to the center of the icon
const verticalTextOffsets = [-6, -6, -7, -9, -10];
// the icon offset is relative to the top left corner of the square icon
const horizontalIconOffsets =  [26, 28, 33, 39, 45];
// distance to center + distance from center to the point of the pin
const verticalIconOffsets = [26 + 18, 28 + 19, 33 + 21, 39 + 25, 45 + 31];

export default class extends Controller {
  static targets = [ "map" ];

  initialize() {
    this.latitude = parseFloat(this.element.getAttribute('data-latitude'));
    this.longitude = parseFloat(this.element.getAttribute('data-longitude'));
    this.zoom = parseInt(this.element.getAttribute('data-zoom'));

    MapConsent.onConsent().then(() => {
      MapsApiLoader.load();
    });

    this.acceptancePoints = {};
  }

  connect() {
    document.querySelector(".acceptance-points__results").addEventListener('volksbank:listUpdated', () => {
      MapsApiLoader.onLoad().then(() => {
        this.loadAcceptancePoints();
      });
    });

    MapsApiLoader.onLoad().then(() => {
      this.initializeMap();
    });
  }

  initializeMap() {
    this.map();
    this.loadAcceptancePoints();
  }

  map() {
    if (this._map) return this._map;

    this._map = new google.maps.Map(this.mapTarget, {
      center: { lat: this.latitude, lng: this.longitude },
      zoom: this.zoom
    });

    this._map.addListener('click', () => {
      if (this._infoWindow) {
        this._infoWindow.close();
      }
    });

    return this._map
  }

  infoWindow() {
    if(this._infoWindow) return this._infoWindow;

    this._infoWindow = new google.maps.InfoWindow();

    return this._infoWindow;
  }

  clusterer(markers = []) {
    if (this._clusterer) return this._clusterer;

    this._clusterer = new MarkerClusterer(this.map(), markers, {
      imageExtension: 'svg',
      imagePath: '../cluster_marker',
      averageCenter: true,
      imageSizes: imageSizes,
      maxZoom: 16
    });

    this._clusterer.getStyles().forEach( (style, index) => {
      style.anchorText = [verticalTextOffsets[index], 0];
      style.anchorIcon = [verticalIconOffsets[index], horizontalIconOffsets[index]];
    });

    return this._clusterer
  }

  loadAcceptancePoints() {
    const acceptancePointElements = document.getElementsByClassName("acceptance-points__group-item");

    this.clusterer().clearMarkers();
    this.acceptancePoints = {};

    Array.from(acceptancePointElements).forEach((element) => {
      const model = {
        id: element.getAttribute('data-acceptance-point-id'),
        name: element.getAttribute('data-acceptance-point-name'),
        latitude: parseFloat(element.getAttribute('data-acceptance-point-latitude')),
        longitude: parseFloat(element.getAttribute('data-acceptance-point-longitude')),
        pinUri: element.getAttribute('data-acceptance-point-pin-uri')
      };

      const acceptancePoint = {
        element: element,
        model: model,
        marker: this.createMarkerFor(model),
        onElementClick: () => {
          window.scrollTo(0, 0);

          this.map().setZoom(17);
          this.map().panTo(acceptancePoint.marker.position);

          acceptancePoint.onMarkerClick();
        },
        onMarkerClick: () => {
          const infoWindow = this.infoWindow();
          const attachedInfo = acceptancePoint.element.getElementsByClassName('acceptance_points__info-box-wrapper')[0].innerHTML;

          infoWindow.setContent(attachedInfo);
          infoWindow.open({
            anchor: acceptancePoint.marker,
            map: this.map(),
            shouldFocus: false,
          });
        }
      };

      this.acceptancePoints[model.id] = acceptancePoint;

      element.addEventListener("click", acceptancePoint.onElementClick);
      acceptancePoint.marker.addListener("click", acceptancePoint.onMarkerClick);
    });

    const markers = Object.values(this.acceptancePoints).map(acceptancePoint => acceptancePoint.marker);
    this.clusterer().addMarkers(markers);
  }

  createMarkerFor(acceptancePoint) {
    return new google.maps.Marker({
      map: this.map(),
      title: acceptancePoint.name,
      icon: {
        url: acceptancePoint.pinUri,
        size: new google.maps.Size(imageSizes[0], imageSizes[0]),
        anchor: new google.maps.Point(horizontalIconOffsets[0], verticalIconOffsets[0]),
      },
      position: {
        lat: acceptancePoint.latitude,
        lng: acceptancePoint.longitude
      }
    })
  }
}
