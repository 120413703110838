import { Controller } from "stimulus"
import * as coreui from '@coreui/coreui';

import MapConsent from '../../js/util/map_consent';

export default class extends Controller {
    static targets = [ 'consentModal', 'consentButton' ];

    initialize() { }

    connect() {
        this.consentButtonTarget.addEventListener('click', () => {
            MapConsent.giveConsent();
        });

        if(!MapConsent.hasConsented()) {
            const modal = new coreui.Modal(this.consentModalTarget);
            modal.show();
        }
    }
}
