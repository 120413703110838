import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "street",
                     "streetNumber",
                     "zipCode",
                     "city",
                     "country",
                     "preview" ]

  connect() {
  }

  preview() {
    let url = this.data.get('url') + '?' + new URLSearchParams({
        street: this.streetTarget.value,
        street_number: this.streetNumberTarget.value,
        zip_code: this.zipCodeTarget.value,
        city: this.cityTarget.value,
        country: this.countryTarget.value
      }).toString();

    fetch(url).then(data => { return data.text() })
              .then(content => { this.previewTarget.innerHTML = content })
  }
}
