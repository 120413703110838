import { Controller } from "stimulus"
import * as coreui from "@coreui/coreui";

export default class extends Controller {
  static targets = ['modal', 'requestButton', 'numberInput', 'balanceCardWrapper', 'loadingSpinner'];

  connect() {
    // capture elements in other variables, so we do not lose them after moving the modal
    this.modalElement = this.modalTarget;
    this.numberInputElement = this.numberInputTarget;
    this.requestButtonElement = this.requestButtonTarget;
    this.balanceCardWrapperElement = this.balanceCardWrapperTarget;
    this.loadingSpinnerElement = this.loadingSpinnerTarget;

    this.$numberInput = $(this.numberInputElement);
    this.$requestButton = $(this.requestButtonElement);
    this.$balanceCardWrapper = $(this.balanceCardWrapperElement);
    this.$loadingSpinner = $(this.loadingSpinnerElement);

    this.$loadingSpinner.hide();

    this.onNumberInputHandler = () => {
      this.$requestButton.prop('disabled', !this.numberInputElement.checkValidity());
    };
    this.$numberInput.on('input', this.onNumberInputHandler);

    this.onRequestButtonClickHandler = () => this.request();
    this.$requestButton.on('click', this.onRequestButtonClickHandler);
  }

  disconnect() {
    this.$numberInput.off('input', this.onNumberInputHandler);
    this.$requestButton.off('click', this.onRequestButtonClickHandler);
  }

  openModal() {
    // we need to move the modal to the body in order for the z-indicies to work as intended
    $(this.modalElement).appendTo(document.body);

    const modal = new coreui.Modal(this.modalElement);
    modal.show();
  }

  request() {
    this.$loadingSpinner.show();
    this.$balanceCardWrapper.html('');

    $.get('/embedding/cards/' + this.numberInputElement.value + '/balance')
      .done((fragment) => {
        this.$balanceCardWrapper.html(fragment);
      })
      .fail(console.error)
      .always(() => this.$loadingSpinner.hide());
  }
}
