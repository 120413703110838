import Cookies from 'js-cookie'

function MapConsent() {
    this.COOKIE_NAME = 'google_maps_consented';
    this.onConsentResolves = [];

    this.hasConsented = function() {
        return Cookies.get(this.COOKIE_NAME) === 'true'
    };

    this.giveConsent = function() {
        if(this.hasConsented()) return;

        Cookies.set(this.COOKIE_NAME, 'true', { sameSite: 'none', secure: true } );

        this.onConsentResolves.forEach((resolve) => resolve());
        this.onConsentResolves = [];
    };

    this.onConsent = function() {
        return new Promise((resolve, reject) => {
            if(this.hasConsented()){
                resolve();
            } else {
                this.onConsentResolves.push(resolve);
            }
        });
    };
}

export default new MapConsent(); // export singleton;
