import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "outlet" ]

  connect() {
  }

  onPostSuccess(event) {
    let [data, status, xhr] = event.detail;
    this.outletTarget.innerHTML = xhr.response;
  }
}
