import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "source", "preview" ]

  connect() {
    this.update();
  }

  update() {
    this.sourceTarget.value = this.sourceTarget.value.replace("#", "");
    let value = this.sourceTarget.value

    if(value.length != 6) {
      this.previewTarget.classList.add("is-invalid");
      this.previewTarget.style.borderLeft = "";
    } else {
      this.previewTarget.style.borderLeft = "10px solid #" + value;
      this.previewTarget.classList.remove("is-invalid");
    }
  }
}
